class SliderProductsWidgetHandler extends elementorModules.frontend.handlers
    .Base {
    updateSlider() {
        const currEle = this.$element[0];
        const productSliderEle = currEle.querySelector(
            ".products-slider__list",
        );

        const slidesPerViewDesktop = this.getElementSettings("total_content");
        const slidesPerViewTablet = this.getElementSettings(
            "total_content_tablet",
        );
        const slidesPerViewMobile = this.getElementSettings(
            "total_content_mobile",
        );

        new Swiper(productSliderEle, {
            slidesPerView: slidesPerViewDesktop || 4,
            rewind: true,
            spaceBetween: 8,
            scrollbar: {
                el: ".products-slider__list .swiper-scrollbar",
                draggable: true,
            },
            breakpoints: {
                0: {
                    slidesPerView: slidesPerViewMobile || 2,
                },
                576: {
                    slidesPerView: slidesPerViewTablet || 2,
                },
                992: {
                    slidesPerView: slidesPerViewDesktop || 4,
                },
            },
            modules: [SwiperModules.scrollbar],
        });
    }

    onInit() {
        this.updateSlider();
    }
}

window.addEventListener("elementor/frontend/init", () => {
    elementorFrontend.elementsHandler.attachHandler(
        "safiro_slider_products",
        SliderProductsWidgetHandler,
    );
});

import Swiper from "swiper";

class ProductGalleryWidgetHandler extends elementorModules.frontend.handlers
    .Base {
    initSlider(ele, config) {
        const totalSlides = JSON.parse(ele.dataset.column);
        let spaceBetween = ele.dataset.spacing || 10;
        this.swiperInstance = new Swiper(ele, {
            rewind: true,
            slidesPerView: parseInt(totalSlides["main_gallery_column"]),
            spaceBetween: parseInt(spaceBetween),
            scrollbar: {
                el: ".single-product-gallery .swiper-scrollbar",
            },
            breakpoints: {
                0: {
                    slidesPerView: totalSlides["main_gallery_column_mobile"]
                        ? parseInt(totalSlides["main_gallery_column_mobile"])
                        : parseInt(totalSlides["main_gallery_column"]),
                    allowTouchMove: true,
                },
                576: {
                    slidesPerView: totalSlides["main_gallery_column_tablet"]
                        ? parseInt(totalSlides["main_gallery_column_tablet"])
                        : parseInt(totalSlides["main_gallery_column"]),
                    allowTouchMove: true,
                },
                992: {
                    allowTouchMove: false,
                    slidesPerView: parseInt(totalSlides["main_gallery_column"]),
                },
            },
            modules: [SwiperModules.scrollbar],
            ...config,
        });
        // console.log(this.swiperInstance);
    }

    updateSlider() {
        const currentEle = this.$element[0];
        const sliderEle = currentEle.querySelector(
            ".single-product-gallery .main-slider",
        );

        this.swiperInstance = null;

        // Init
        if (window.innerWidth <= 992) {
            this.initSlider(sliderEle);
        }

        // Destroy on Desktop
        window.addEventListener("resize", () => {
            if (window.innerWidth <= 992) {
                this.initSlider(sliderEle);
            } else {
                console.log(this);
                if (this.swiperInstance) {
                    // this.swiperInstance.destroy();
                    // this.swiperInstance = null;
                }
            }
        });
        const classInstance = this;
        jQuery(function ($) {
            // Step 1: Store the initial image data
            const initialImageData = {
                src: $(".primary-img").attr("src"),
                srcset: $(".primary-img").attr("srcset"),
            };

            // Event listener for when a variation is selected
            $(".single_variation_wrap").on(
                "show_variation",
                function (event, variation) {
                    // Fade-in animation and update the image with the selected variation
                    gsap.fromTo(
                        ".primary-img",
                        { opacity: 0 },
                        { opacity: 1, duration: 1.5, ease: "power2.out" },
                    );

                    $(".primary-img").attr({
                        src: variation.image.src,
                        srcset: variation.image.srcset,
                    });

                    // Guard to ensure swiperInstance exists
                    if (!this.swiperInstance) {
                        return;
                    }
                }.bind(classInstance),
            );

            // Step 2: Add event listener for the "Reset Variation" button
            $(".reset_variations").on("click", function () {
                // Fade-in animation and reset the image back to the initial state
                gsap.fromTo(
                    ".primary-img",
                    { opacity: 0 },
                    { opacity: 1, duration: 1.5, ease: "power2.out" },
                );

                $(".primary-img").attr({
                    src: initialImageData.src,
                    srcset: initialImageData.srcset,
                });
            });
        });
    }

    onInit() {
        this.updateSlider();
    }
}

window.addEventListener("elementor/frontend/init", () => {
    elementorFrontend.elementsHandler.attachHandler(
        "safiro_single_product_gallery",
        ProductGalleryWidgetHandler,
    );
});

import Alpine from "alpinejs";
import ajax from "@imacrayon/alpine-ajax";
import { collapse } from "@alpinejs/collapse";
import Iconify from "@iconify/iconify";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../../node_modules/jquery-parallax.js/parallax";

import "../css/style.scss";
import "./alpine";
import "./slider";
import "./elementor/product-gallery.js";
import "./elementor/slider-products.js";

window.Iconify = Iconify;

// Alpine
window.Alpine = Alpine;
Alpine.plugin(ajax);
Alpine.plugin(collapse);
Alpine.start();

gsap.registerPlugin(ScrollTrigger);
window.gsap = gsap;

const actionBar = document.querySelector(".action-bar");
const actionBarList = document.querySelector(".action-bar-items");
const actionBarItems = document.querySelectorAll(".action-bar-item");
const actionBarContainer = document.querySelector(".action-bar-container");
const frontIcon = document.querySelector(".front-image");
let isActive = false;

const crossIcon =
    '<span class="iconify-inline" data-icon="material-symbols:close"></span>';
const chatIcon = `<span class="iconify-inline" data-icon="et:chat"></span><p>Contact Us</p>`;

frontIcon.innerHTML = chatIcon;
gsap.set(frontIcon, { cursor: "pointer" });
gsap.set(actionBarList, { y: -50 });
gsap.set(actionBarItems, { opacity: 0 });

function toggleWidget() {
    isActive = !isActive;

    actionBarContainer.dataset.isActive = isActive;

    gsap.to([actionBarList, actionBarItems], {
        y: isActive ? -5 : 50,
        duration: 0.5,
        opacity: isActive ? 1 : 0,
        stagger: 0.1,
    });

    gsap.to(frontIcon, {
        duration: 0.1,
        scale: isActive ? 0.8 : 0.5,
        ease: "power2.out",
        onComplete: () => {
            frontIcon.innerHTML = isActive ? crossIcon : chatIcon;

            gsap.to(frontIcon, {
                duration: 0.1,
                scale: 1,
                ease: "power2.in",
            });

            gsap.to(frontIcon, {
                background: isActive ? "transparent" : "",
            });
        },
    });
}

actionBar.addEventListener("click", toggleWidget);
document.addEventListener("click", (event) => {
    const isClickInsideWidget = actionBar.contains(event.target);
    if (!isClickInsideWidget && isActive) {
        toggleWidget();
    }
});

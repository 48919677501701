// GLOBAL VALUE
const ajaxUrl = ajax_obj.url;
const ajaxNonce = ajax_obj.nonce;

// ALPINE
document.addEventListener("alpine:init", () => {
    Alpine.store("sizeGuideSlide", {
        open: false,
        desktopOpen: false,
        toggle() {
            this.open = !this.open;
        },
        toggleOff() {
            this.open = false;
        },
        toggleOn() {
            this.open = true;
        },
    });

    Alpine.data("SizeGuideBtn", () => ({
        // Method
        handleToggleModal() {
            const sizeGuide = this.$store.SizeGuide;

            sizeGuide.toggleSizeGuideModal();
        },
    }));

    Alpine.store("SizeGuide", {
        // Props
        isShowSizeGuide: false,
        // Method
        toggleSizeGuideModal() {
            this.isShowSizeGuide = !this.isShowSizeGuide;

            // Hidden Scroll
            const htmlEle = window.document.documentElement;
            htmlEle.classList.toggle("overflow-hidden");
        },
    });

    // GLOBAL
    Alpine.store("global", {
        showCart: false,
        // Method
        setShowCart(newData) {
            this.showCart = newData;
        },
        getShowCart() {
            return this.showCart;
        },
        displayedShowCart() {
            // Get navbar ele
            const navEle = document.querySelector("nav .nav-wrapper");
            if (navEle.classList.contains("nav-wrapper--down")) {
                navEle.classList.remove("nav-wrapper--down");
            }

            setTimeout(() => {
                const navEle = document.querySelector("nav .nav-wrapper");
                if (navEle.classList.contains("nav-wrapper--down")) {
                    navEle.classList.remove("nav-wrapper--down");
                }

                // Toggle cart
                this.showCart = true;

                // Disable scroll
                document.documentElement.classList.add("overflow-hidden");
            }, 600);
        },
    });

    // GLOBAL - MODAL
    Alpine.store("ModalVariation", {
        totalAttribute: null,
        selectedAttribute: {},
        selectedVariationId: null,
        linkAddToCart: "?add-to-cart=",
        handleSelectAttribute() {
            const grandParentEle = this.$el.closest(
                ".modal-variation__attribute",
            );
            const parentEle = this.$el.closest(
                ".modal-variation__attribute-list",
            );

            // Remove temp active
            const prevActiveBtn = parentEle.querySelector(".item.active");
            if (prevActiveBtn) {
                prevActiveBtn.classList.remove("active");
            }

            this.$el.classList.add("active");

            const attributeName = grandParentEle.dataset.attributeName;
            this.selectedAttribute = {
                ...this.selectedAttribute,
                [attributeName]: this.$el.dataset.slug,
            };

            const modalVariationEle =
                document.querySelector(".modal-variation");
            this.linkAddToCart = `?add-to-cart=${modalVariationEle.dataset.productId}`;
            const variationsJson = JSON.parse(
                modalVariationEle.dataset.variation,
            );

            // Enable add to cart button
            if (
                Object.keys(this.selectedAttribute).length ===
                Object.keys(variationsJson[0].attributes).length
            ) {
                modalVariationEle.querySelector(
                    ".modal-variation__cta form button",
                ).disabled = false;
            }

            for (let obj of variationsJson) {
                if (
                    this.$store.ModalVariation.objectsAreEqual(
                        this.selectedAttribute,
                        obj.attributes,
                    )
                ) {
                    // Change action form
                    this.selectedVariationId = obj.id;
                    this.linkAddToCart += `&variation_id=${obj.id}`;
                    modalVariationEle.querySelector(
                        ".modal-variation__cta > form",
                    ).action = this.linkAddToCart;

                    console.log(obj);

                    return obj;
                }
            }
        },
        objectsAreEqual(obj1, obj2) {
            const obj1Keys = Object.keys(obj1);
            const obj2Keys = Object.keys(obj2);

            if (obj1Keys.length !== obj2Keys.length) {
                // return 'not same length';
                return false;
            }

            for (let key of obj1Keys) {
                if (obj1[key] !== obj2[key]) {
                    // return 'diff value';
                    return false;
                }
            }

            return true;
        },
    });

    // ALPINE - NAVBAR
    Alpine.data("Navbar", () => ({
        // INIT
        init() {
            // get total navbar height
            const navbarWrapperEle = this.$el;
            window.document.addEventListener("DOMContentLoaded", () => {
                document.body.style.setProperty(
                    "--navbar-height",
                    `${navbarWrapperEle.clientHeight}px`,
                );
            });

            // get navbar brand height
            const navbarBrandEle = this.$refs["navbar-brand"];
            this.$el.style.setProperty(
                "--navbar-brand-height",
                `${navbarBrandEle.clientHeight}px`,
            );
            // get nav promotion height
            const navbarPromotionEle = this.$refs["nav-promotion"];
            if (navbarPromotionEle) {
                this.$el.style.setProperty(
                    "--navbar-promotion-height",
                    `${navbarPromotionEle.clientHeight}px`,
                );
            }
        },
        // PROP - SIDEBAR
        showProfile: false,
        // showCart: false, // Move to global
        showSearch: false,
        // Promotion
        activePromotion: 0,
        // PROP - MOBILE
        showNavMobile: false,
        showNavLanguage: false,
        // METHOD
        hideBody() {
            const htmlEle = document.documentElement;
            htmlEle.classList.toggle("overflow-hidden");
        },
        toggleNavMobile() {
            this.showSearch = false;
            // this.showCart = false;
            this.$store.global.setShowCart(false);
            this.showProfile = false;
            const navMobileEle = this.$refs["nav-mobile"];
            navMobileEle.classList.toggle("active");
            this.hideBody();
        },
        toggleProfile() {
            this.showSearch = false;
            // this.showCart = false;
            this.$store.global.setShowCart(false);
            this.showProfile = !this.showProfile;
            this.hideBody();
        },
        // METHOD - CART
        toggleCart() {
            const bodyIsHidden =
                document.documentElement.classList.contains("overflow-hidden");

            // Check if user clicked from Profile Sidebar to Cart sidebar, don't need to hide body again
            if (!(this.showProfile && bodyIsHidden)) {
                this.hideBody();
            }

            this.showSearch = false;
            this.showProfile = false;
            // this.showCart = !this.showCart;
            this.$store.global.setShowCart(!this.$store.global.getShowCart());
        },
        closeCart() {
            // this.showCart = false;
            this.$store.global.setShowCart(false);
            this.hideBody();
        },
        // METHOD - SEARCH
        toggleSearch() {
            // this.showCart = false;
            this.$store.global.setShowCart(false);
            this.showProfile = false;
            this.showSearch = !this.showSearch;
        },
        // METHOD - NAV PROMOTION
        prevPromotion() {
            const totalItemsPromotion =
                this.$refs.navPromotionList.children.length;

            if (this.activePromotion == 0) {
                this.activePromotion = totalItemsPromotion - 1;
            } else {
                this.activePromotion--;
            }

            this.changePromotion();
        },
        nextPromotion() {
            const totalItemsPromotion =
                this.$refs.navPromotionList.children.length;

            if (this.activePromotion == totalItemsPromotion - 1) {
                this.activePromotion = 0;
            } else {
                this.activePromotion++;
            }

            this.changePromotion();
        },
        changePromotion() {
            const childrenPromotionList = this.$refs.navPromotionList.children;
            const prevPromotionEle =
                this.$refs.navPromotionList.querySelector(".is-selected");

            if (prevPromotionEle) {
                prevPromotionEle.classList.remove("is-selected");
            }

            childrenPromotionList[this.activePromotion].classList.add(
                "is-selected",
                "in",
            );
            setTimeout(() => {
                childrenPromotionList[this.activePromotion].classList.remove(
                    "in",
                );
            }, 250);
        },
    }));

    // Alpine - Navbar Child
    Alpine.data("NavbarHasChild", () => ({
        itemOnHover: 0,
        handleOnHover(depth) {
            console.log("CHILD DEPTH:", depth);

            if (depth == 2) {
                // grandchild
                // reduce opacity for child item
                console.log("ELE:", this.$el);
                const parentEle = this.$el.closest('.sub-menu[data-depth="0"]');
                console.log("Parent Ele:", parentEle);
                parentEle.classList.add("grandchild-hover");
            }

            // Change image on hover
            // Prepare ele
            const menuListEle = this.$refs.subMenuListEle;
            const imagesEle = this.$refs.subMenuImagesEle;
            const currEle = this.$el;
            const dataId = currEle.dataset.id;

            // Query Images Ele
            const selectImgItem = imagesEle.querySelector(
                `.item[data-id='${dataId}']`,
            );
            const currActiveImg = imagesEle.querySelector(".item.active");
            const currActiveItem =
                menuListEle.querySelector(".menu-item.active");

            // Toggle Class
            if (currActiveItem) {
                currActiveItem.classList.remove("active");
            }
            currEle.classList.add("active");
            if (currActiveImg) {
                currActiveImg.classList.remove("active");
            }
            if (selectImgItem) {
                selectImgItem.classList.add("active");
            }
        },
        handleHoverOnLeave(depth) {
            if (depth == 2) {
                // grandchild
                // reduce opacity for child item
                // console.log(this.$el);
                const parentEle = this.$el.closest('.sub-menu[data-depth="0"]');
                parentEle.classList.remove("grandchild-hover");
            }
        },
    }));

    // ALPINE - CART SIDEBAR
    Alpine.data("CartSidebarItem", (itemQty, productId, variationId) => ({
        // INIT
        init() {
            this.cartSidebarEle = document.getElementById("nav-sidebar-cart");
            this.footerPriceEle = document.getElementById(
                "nav-sidebar-cart-total-summary-amount",
            );
        },
        // PROP
        productId,
        variationId,
        itemQty,
        footerPriceEle: null,
        cartSidebarEle: null,
        // METHOD
        removeProduct: async function () {
            const productId = this.productId;

            // Add Loading
            this.cartSidebarEle.setAttribute("aria-busy", "");

            try {
                const res = await fetch(ajaxUrl, {
                    method: "POST",
                    body: new URLSearchParams({
                        action: "remove_product_cart_sidebar",
                        product_id: productId,
                        variation_id: variationId,
                        nonce: ajaxNonce,
                    }),
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                });

                if (!res.ok) {
                    throw new Error("Cant connect");
                }

                const json = await res.json();

                if (json.success) {
                    // Remove Item
                    const parentEle = this.$el.closest(".nav-cart-item");
                    parentEle.remove();

                    // Update total price
                    this.footerPriceEle.innerHTML = json.data.totalHTML;
                }
            } catch (error) {
                console.error("Error occurred:", error.message);
            }

            // Remove loading
            this.cartSidebarEle.removeAttribute("aria-busy");
        },
        changeQty: async function (inNewVal = false) {
            const cartItemKey = this.$el.dataset.key;
            const oldVal = this.itemQty;
            const newVal = Number.isInteger(inNewVal)
                ? inNewVal
                : this.$el.value;

            // Add Loading
            const cartItem = this.$el.closest(".nav-cart-item");
            cartItem.setAttribute("aria-busy", "");

            try {
                const res = await fetch(ajaxUrl, {
                    method: "POST",
                    body: new URLSearchParams({
                        action: "update_cart_item_qty",
                        hash: cartItemKey,
                        qty: newVal,
                        nonce: ajaxNonce,
                    }),
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                });

                if (!res.ok) {
                    throw new Error("Cant connect");
                }

                const json = await res.json();

                if (newVal == 0 || newVal < 1) {
                    cartItem.remove();
                }

                if (json.success) {
                    this.footerPriceEle.innerHTML = json.data.totalHTML;
                }
            } catch (e) {
                console.error("Error:", e.message);

                // Reset value
                const qtyInput = this.$refs.productQuantityInput;
                qtyInput.value = oldVal;

                cartItem.removeAttribute("aria-busy");
                return false;
            }

            cartItem.removeAttribute("aria-busy");
            return true;
        },
        incrementQty: async function () {
            this.itemQty++;

            const changeQty = await this.changeQty(this.itemQty);
            // Dont change the qty when not success
            if (changeQty == false) {
                this.itemQty--;
            }
        },
        decrementQty: async function () {
            if (this.itemQty == 1) {
                const confirmRemoveProduct = confirm("Remove product?");
                if (confirmRemoveProduct) {
                    this.removeProduct();
                    return;
                }
            }

            this.itemQty--;

            const changeQty = await this.changeQty(this.itemQty);
            // Dont change the qty when not success
            if (changeQty == false) {
                this.itemQty++;
            }
        },
    }));

    // ALPINE - LAYOUT 1
    Alpine.data("LayoutOne", () => ({
        // INIT
        init() {
            console.log("Hello World");
            const parentEle = this.$el;
            // Prep Ele
            this.navEle = parentEle.querySelector(".layout-1__nav");
            this.navItemsEle = this.navEle.querySelectorAll(".nav-item");
            this.contentEle = parentEle.querySelector("#layout-1-content");
        },
        // PROP
        // PROP - ELE
        contentEle: null,
        navEle: null,
        navItemsEle: null,
        // METHOD
    }));

    // Shop - Product Item
    Alpine.data("ShopProductItem", () => ({
        // INIT
        // PROP
        // openModal: false,
        // METHOD
        async handleProductVariable() {
            const productId = this.$el.dataset.productId;

            const variationId = await this.fetchProductVariable(productId);
        },
        fetchProductVariable(productId) {
            const modalVariationEle =
                document.querySelector(".modal-variation");

            return new Promise(async (resolve, reject) => {
                // Show modal
                // this.$refs.modalWrapper.classList.add('show');

                document.body.setAttribute("aria-busy", "");

                // Get product variation
                try {
                    const res = await fetch(ajaxUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        body: new URLSearchParams({
                            action: "get_variation_product_async",
                            product_id: productId,
                            nonce: ajaxNonce,
                        }),
                    });

                    if (!res.ok) {
                        throw new Error("Connection");
                    }

                    const json = await res.json();

                    // Prepare data HTML
                    const data = json.data;
                    console.log(data);
                    const modalWrapperEle =
                        document.querySelector(".modal-wrapper");
                    modalWrapperEle.innerHTML = data["product_html"];
                    // this.$refs.modalWrapper.innerHTML = data["product_html"];

                    // Remove loading
                    document
                        .querySelector(".is-loading-ele")
                        .classList.add("finished");
                    setTimeout(() => {
                        document
                            .querySelector(".is-loading-ele")
                            .classList.remove("finished");
                        document.body.removeAttribute("aria-busy");

                        // Show Modal After finish loading
                        // this.$refs.modalWrapper.classList.add("show");
                        modalWrapperEle.classList.add("show");
                    }, 1000);
                } catch (error) {
                    console.error("Error:", error);
                }
            });
        },
    }));
});

document.addEventListener("DOMContentLoaded", function () {
    const navEle = document.querySelector("nav.nav");
    const navWrapper = document.querySelector(".nav-wrapper");
    const isMobile = window.innerWidth < 576;
    const isDesktop = window.innerWidth > 576;

    // NAV MOBILE
    const navMobileMenuEle = document.querySelector(".nav-mobile__menus");
    const menuItemHasChildrenEle = navMobileMenuEle.querySelectorAll(
        ".menu-item-has-children",
    );
    // Add toggle menu child
    menuItemHasChildrenEle.forEach((menuEle) => {
        // Child item
        const menuItemEle = menuEle.querySelector(".menu-link");

        // Get Submenu height
        const subMenuEle = menuEle.querySelector(".sub-menu");
        subMenuEle.style.setProperty(
            "--sub-menu-height",
            `calc(${subMenuEle.clientHeight}px + 12px)`,
        );
        subMenuEle.classList.add("not-show");

        // Handle click menu item
        menuEle.addEventListener("click", function () {
            menuItemEle.classList.toggle("active");
            // subMenuEle.classList.toggle("not-show");
            subMenuEle.classList.toggle("active");
            menuEle.querySelector("button").classList.toggle("active");
        });
    });

    // ON RESIZE
    const navEleIsOld = navEle.dataset.type == "one";
    document.addEventListener("resize", function () {
        if (
            navWrapper.classList.contains("nav-wrapper--down") &&
            isMobile &&
            navEleIsOld
        ) {
            navWrapper.classList.remove("nav-wrapper--down");
        }
    });

    // ON SCROLL
    let oldScrollY = window.scrollY;
    document.addEventListener("scroll", function () {
        // SCROLL DOWN
        if (oldScrollY < window.scrollY && isDesktop && navEleIsOld) {
            // console.log('Scroll Down');
            if (!navWrapper.classList.contains("nav-wrapper--down")) {
                navWrapper.classList.add("nav-wrapper--down");
            }
        }
        // SCROLL UP
        if (oldScrollY > window.scrollY && isDesktop && navEleIsOld) {
            // console.log('Scroll Up');
            if (navWrapper.classList.contains("nav-wrapper--down")) {
                navWrapper.classList.remove("nav-wrapper--down");
            }
        }
        oldScrollY = window.scrollY;
    });
});

// CUSTOM FUNCTION =============================================================
// Change Elementor Content
const layoutOneNavEle = document.querySelector(".layout-1__nav");
if (layoutOneNavEle) {
    async function changeContentElementor(e) {
        const parentEle = document.querySelector("#layout-1");
        const contentEle = document.querySelector("#layout-1-content");

        parentEle.classList.add("is-loading");
        contentEle.classList.add("is-loading");

        try {
            const res = await fetch(ajaxUrl, {
                method: "POST",
                body: new URLSearchParams({
                    action: "get_elementor_content",
                    post_slug: e.dataset.link,
                    nonce: ajaxNonce,
                }),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });

            if (!res.ok) {
                console.error("Failed to fetch Elementor content");
            }

            const json = await res.json();

            if (json.success) {
                parentEle.innerHTML = json.data.html;
            }

            parentEle.classList.remove("is-loading");
            contentEle.classList.remove("is-loading");
        } catch (error) {
            console.error("Error occurred:", error);
        }
    }
    window.changeContentElementor = changeContentElementor;
}
